<template>
  <div class="present">
    <div class="yiqi">
      <div>
        <div @click="num = 0" :class="{ colors: num == 0 }">
          <i class="el-icon-s-order"></i>封面
        </div>
        <div @click="xiaxiasa" :class="{ colors: num == 1 && isShow == true }">
          <i class="el-icon-s-data"></i> 基本信息表
        </div>
        <div @click="clickXiasa" :class="{ colors: num == 1 && isShow == 2 }">
          <i class="el-icon-s-data"></i> 单位信息表
        </div>
        <div @click="clickXia1se" :class="{ colors: num == 1 && isShow == 6 }">
          <i class="el-icon-s-data"></i> 单位/完成人
        </div>
        <div @click="xiyibuasa" :class="{ colors: num == 1 && isShow == 3 }">
          <i class="el-icon-s-data"></i> 完成单位情况表
        </div>
        <div @click="click1se" :class="{ colors: num == 1 && isShow == 4 }">
          <i class="el-icon-s-data"></i> 完成人情况表
        </div>
        <div @click="clickXia1s1e" :class="{ colors: num == 1 && isShow == 5 }">
          <i class="el-icon-s-data"></i> 推荐意见表
        </div>

        <div @click="num = 2" :class="{ colors: num == 2 }">
          <i class="el-icon-s-order"></i>上传申报正文
        </div>
        <div @click="num = 3" :class="{ colors: num == 3 }">
          <i class="el-icon-s-data"></i>上传申报附件
        </div>
        <div @click="num = 4" :class="{ colors: num == 4 }">
          <i class="el-icon-s-data"></i>提交申报材料
        </div>
      </div>

      <div class="main">
        <div>
          <div v-if="num == 0">
            <div class="fengmian" id="printTest" ref="imageWrapper">
              <div>
                <div>2023年产学研合作创新成果奖</div>
                <div>申报材料</div>
              </div>
              <div
                style="width: 80%; padding: 20px 180px; box-sizing: border-box"
              >
                <div>
                  <div style="margin: 40px 0">申报单位:(加盖公章)</div>
                  <input
                    v-if="iss"
                    type="text"
                    v-model="fengmian.cover_unit"
                    style="width: 100%"
                  />
                  <div
                    style="
                      text-align: left;
                      border-bottom: 1px solid #999;
                      width: 100%;
                    "
                    v-else
                    v-html="fengmian.cover_unit"
                  ></div>
                </div>
                <div style="margin: 10px 0">
                  <div style="width: ">主要完成单位：</div>
                  <!-- <textarea
                v-if="iss"
                type="text"
                style="border-bottom:1px solid #000;width:200px;height:20px"
                v-model="fengmian.cover_success"
              />-->
                  <textarea
                    v-if="iss"
                    type="text"
                    style="
                      border: 1px solid #000;
                      width: 100%;
                      font-size: 30px;
                      height: 20px;
                    "
                    v-model="fengmian.cover_success"
                  />
                  <div
                    style="
                      text-align: left;
                      border-bottom: 1px solid #999;
                      width: 100%;
                    "
                    v-else
                    v-html="fengmian.cover_success"
                  ></div>
                </div>
                <div style="margin: 10px 0">
                  <div>主要完成人：</div>
                  <textarea
                    v-if="iss"
                    type="text"
                    style="
                      border: 1px solid #000;
                      font-size: 30px;
                      width: 100%;
                      height: 20px;
                    "
                    v-model="fengmian.cover_name"
                  />
                  <div
                    style="
                      text-align: left;
                      border-bottom: 1px solid #999;
                      width: 100%;
                    "
                    v-else
                    v-html="fengmian.cover_name"
                  ></div>
                </div>
                <div>
                  <div>申报时间：</div>
                  <input
                    v-if="iss"
                    type="text"
                    style="width: 100%"
                    v-model="fengmian.cover_time"
                  />
                  <div
                    style="
                      text-align: left;
                      border-bottom: 1px solid #999;
                      width: 100%;
                    "
                    v-else
                    v-html="fengmian.cover_time"
                  ></div>
                </div>
              </div>
            </div>
            <div class="feng_btn">
              <el-button @click="xiaxia" type="primary">保存</el-button>
              <el-button @click="fengmianxiazai" type="primary">下载</el-button>
            </div>
          </div>
          <div v-else-if="num == 1" class="shenMain">
            <div class="tabls" v-if="isShow == true">
              <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
                <div>
                  <h3 class="zzzzzz">申报基本信息</h3>
                </div>
                <table border="1" cellpading="0" cellspace="0">
                  <tbody>
                    <tr>
                      <td style="text-align: center" colspan="2">项目名称</td>
                      <td colspan="3" height="80px">
                        <input
                          type="text"
                          v-model="xinxi.project_name"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="text-align: center" height="80px">
                        项目所属行业领域
                      </td>
                      <td colspan="3">
                        <div class="xuanze">
                          <div v-for="(item, index) in select" :key="index">
                            <input
                              type="checkbox"
                              :checked="
                                !shuzu ? '' : shuzu.indexOf(item.id) >= 0
                              "
                              name="checkboxinput"
                              class="input-checkbox"
                              @click="checkedOne(item.id)"
                            />
                            <span v-if="item.name != '其他'">{{
                              item.name
                            }}</span>
                            <span v-else>
                              <input
                                type="text"
                                v-model="xinxi.domain_rests"
                                placeholder="其他"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" style="text-align: center">申报单位</td>
                      <td colspan="4" height="80px">
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;
                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi.declare_unit"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 15px 10px;
                          "
                          v-else
                          v-html="xinxi.declare_unit"
                        ></div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" style="text-align: center">
                        主要完成单位
                      </td>
                      <td colspan="4" height="80px">
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;

                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi.accomplish_unit"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 15px 10px;
                          "
                          v-else
                          v-html="xinxi.accomplish_unit"
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="text-align: center">主要完成人</td>
                      <td colspan="4" height="80px">
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;
                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi.accomplish_man"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 15px 10px;
                          "
                          v-else
                          v-html="xinxi.accomplish_man"
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="text-align: center">
                        推荐单位
                        <div>/推荐专家</div>
                      </td>
                      <td colspan="4">
                        <!-- <input
                          type="text"
                          v-model="xinxi.recommend_unit"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        /> -->
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;
                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi.recommend_unit"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 15px 10px;
                          "
                          v-else
                          v-html="xinxi.recommend_unit"
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="text-align: center">任务来源</td>
                      <td colspan="4" height="80px">
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;
                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi.source"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 15px 10px;
                          "
                          v-else
                          v-html="xinxi.source"
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" height="200px">
                        <div>
                          <div style="text-align: left; box-sizing: border-box">
                            具体计划、基金名称和编号：
                          </div>
                          <el-input
                            v-if="iss"
                            type="textarea"
                            cols="70"
                            style="font-size: 30px; width: 100%"
                            rows="8"
                            v-model="xinxi.fund_number"
                          ></el-input>
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 15px 10px;
                            "
                            v-else
                            v-html="xinxi.fund_number"
                          ></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td height="80px" colspan="1" style="text-align: center">
                        授权发明专利（项）
                      </td>
                      <td colspan="2">
                        <input
                          type="text"
                          v-model="xinxi.patent_invention"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-align: center;
                          "
                        />
                      </td>
                      <td colspan="1" style="text-align: center">
                        授权的其他知识产权（项）
                      </td>
                      <td colspan="2">
                        <input
                          type="text"
                          v-model="xinxi.authorization"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-align: center;
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1" style="text-align: center" height="80px">
                        项目起止时间
                      </td>
                      <td colspan="2">
                        <input
                          type="text"
                          v-model="xinxi.project_start"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-align: center;
                          "
                        />
                      </td>
                      <td colspan="1" style="text-align: center">完成时间</td>
                      <td colspan="2">
                        <input
                          type="text"
                          v-model="xinxi.project_end"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-align: center;
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="feng_btn">
                <el-button @click="clickXia" type="primary">保存</el-button>
                <el-button @click="clickXias" type="primary">下载</el-button>
              </div>
            </div>
            <div v-if="isShow == 2">
              <div class="tabls">
                <div
                  id="printTest"
                  ref="imageWrapper"
                  style="padding-top: 100px"
                >
                  <div>
                    <h3 class="zzzzzz">申报单位信息</h3>
                  </div>
                  <table border="1" cellpading="0" cellspace="0">
                    <tbody>
                      <tr>
                        <td width="10%" style="text-align: center">
                          申报单位名称
                        </td>
                        <td width="10%" colspan="4">
                          <!-- <input
                            type="text"
                            v-model="xinxi1.work_unit"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          /> -->
                          <textarea
                            v-if="iss"
                            type="text"
                            style="
                              border: 1px solid #000;
                              width: 100%;

                              height: 100%;
                              box-sizing: border-box;
                              padding: 0 10px;
                              font-size: 28px;
                            "
                            v-model="xinxi1.work_unit"
                          />
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 0 10px;
                            "
                            v-else
                            v-html="xinxi1.work_unit"
                          ></div>
                        </td>
                        <td width="10%" style="text-align: center">信用代码</td>
                        <td width="10%" colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.credit_code"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          单位负责人
                        </td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi1.user_name"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td style="text-align: center">职 务</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.job"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          单位地址
                        </td>
                        <td colspan="4">
                          <!-- <input
                            type="text"
                            v-model="xinxi1.unit_address"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          /> -->
                          <textarea
                            v-if="iss"
                            type="text"
                            style="
                              border: 1px solid #000;
                              width: 100%;

                              height: 100%;
                              box-sizing: border-box;
                              padding: 0 10px;
                              font-size: 28px;
                            "
                            v-model="xinxi1.unit_address"
                          />
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 0 10px;
                            "
                            v-else
                            v-html="xinxi1.unit_address"
                          ></div>
                        </td>
                        <td style="text-align: center">邮 编</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.postcode"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          电话
                          <div>（加区号）</div>
                        </td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi1.unit_tel"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td style="text-align: center">手机</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.unit_mobile"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          联系人
                          <div>（联系部门）</div>
                        </td>
                        <td colspan="4">
                          <!-- <input
                            type="text"
                            v-model="xinxi1.department"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          /> -->
                          <textarea
                            v-if="iss"
                            type="text"
                            style="
                              border: 1px solid #000;
                              width: 100%;

                              height: 100%;
                              box-sizing: border-box;
                              padding: 0 10px;
                              font-size: 28px;
                            "
                            v-model="xinxi1.department"
                          />
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 0 10px;
                            "
                            v-else
                            v-html="xinxi1.department"
                          ></div>
                        </td>
                        <td style="text-align: center">手机</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.department_mobile"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          电话
                          <div>（加区号）</div>
                        </td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi1.tel_mobile"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td style="text-align: center">电子邮箱</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.email"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          单位职
                          <div>工人数</div>
                        </td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi1.unit_num"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td style="text-align: center">
                          大专以上人员
                          <div>占职工比例</div>
                        </td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.unit_num_ratio"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          上一年
                          <div>总收入</div>
                        </td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi1.old_year_earning"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td style="text-align: center">
                          上一年
                          <div>单位总资产</div>
                        </td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.old_year_profit"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center" height="80px">
                          上一年研
                          <div>发经费投入</div>
                        </td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi1.old_year_expenditure"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td style="text-align: center">
                          上一年研发经费占
                          <div>单位总成本的比例</div>
                        </td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi1.old_year_ratio"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="8" height="220px">
                          <div class="biaoge">
                            <div>
                              <div>
                                本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                              </div>
                            </div>
                            <div>
                              <div style="margin-left: 50px">
                                科研管理部门（盖章）
                              </div>
                              <div>申报单位（盖章）</div>
                            </div>
                            <div class="faren">
                              <div>法人代表签名：</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: center">日期</td>
                        <td colspan="6">
                          <input
                            type="text"
                            style="width: 100%; text-align: center"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="feng_btn">
                <el-button @click="xiyibua" type="primary">保存</el-button>
                <el-button @click="xiyibuas" type="primary">下载</el-button>
              </div>
            </div>
            <div v-if="isShow == 6">
              <div class="w-c">
                <div>
                  <div>完成单位数量</div>
                  <div>
                    <el-select v-model="wanchenggongsi" placeholder="请选择">
                      <el-option label="3" value="3"> </el-option>
                      <el-option label="4" value="4"> </el-option>
                      <el-option label="5" value="5"> </el-option>
                    </el-select>
                  </div>
                </div>
                <div>
                  <div>完成人数量</div>
                  <div>
                    <el-select v-model="wanchengren" placeholder="请选择">
                      <el-option label="5" value="5"> </el-option>
                      <el-option label="6" value="6"> </el-option>
                      <el-option label="7" value="7"> </el-option>
                      <el-option label="8" value="8"> </el-option>
                      <el-option label="9" value="9"> </el-option>
                      <el-option label="10" value="10"> </el-option>
                    </el-select>
                  </div>
                </div>
                <!-- <div class="feng_btn">
                  <el-button @click="xiyibuasa" type="primary">下一页</el-button>
                </div> -->
              </div>
            </div>
            <div v-if="isShow == 3">
              <div v-if="danweiShow">
                <div class="w-cs">
                  <div v-for="item in Number(wanchenggongsi)" :key="item">
                    <div>完成单位{{ item }}</div>
                    <el-button type="primary" @click="getDanwei(item)"
                      >填写</el-button
                    >
                    <el-button type="primary" @click="getDanwei(item)"
                      >查看</el-button
                    >
                  </div>
                </div>
                <div class="feng_btn">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <div>填写每个表格保存后，请点击提交上传至系统保存</div>
                    <el-button @click="clickXia1s" type="primary"
                      >提交</el-button
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="tabls">
                  <div>第{{ getDanweiNum + 1 }}个完成单位</div>
                  <div
                    id="printTest"
                    ref="imageWrapper"
                    style="padding-top: 100px"
                  >
                    <div>
                      <h3 class="zzzzzz">完成单位情况表</h3>
                    </div>
                    <table border="1" cellpading="0" cellspace="0">
                      <tbody>
                        <tr>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                          >
                            完成单位名称
                          </td>
                          <td colspan="2">
                            <textarea
                              v-if="iss"
                              type="text"
                              style="
                                border: 1px solid #000;
                                width: 100%;
                                height: 90%;
                                box-sizing: border-box;
                                padding: 0 10px;
                                font-size: 28px;
                              "
                              v-model="accomplish[getDanweiNum].company"
                            />
                            <div
                              style="
                                text-align: left;
                                box-sizing: border-box;
                                padding: 0 10px;
                              "
                              v-else
                              v-html="accomplish[getDanweiNum].company"
                            ></div>
                            <!-- <input type="text" v-model="accomplish[0].company" style="width:300px" /> -->
                          </td>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                          >
                            信用代码
                          </td>
                          <td colspan="3">
                            <input
                              type="text"
                              v-model="accomplish[getDanweiNum].code"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="text-align: center"
                            colspan="2"
                            height="80px"
                            width="10%"
                          >
                            通讯地址
                          </td>
                          <td colspan="2">
                            <textarea
                              v-if="iss"
                              type="text"
                              style="
                                border: 1px solid #000;
                                width: 100%;
                                height: 90%;
                                box-sizing: border-box;
                                padding: 0 10px;
                                font-size: 28px;
                              "
                              v-model="accomplish[getDanweiNum].site"
                            />
                            <div
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                              v-else
                              v-html="accomplish[getDanweiNum].site"
                            ></div>
                          </td>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                          >
                            邮 编
                          </td>
                          <td colspan="3">
                            <input
                              type="text"
                              v-model="accomplish[getDanweiNum].postcode"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                            height="80px"
                          >
                            联系人
                          </td>
                          <td colspan="2">
                            <input
                              type="text"
                              v-model="accomplish[getDanweiNum].linkman"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                            />
                          </td>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                          >
                            手机
                          </td>
                          <td colspan="3">
                            <input
                              type="text"
                              v-model="accomplish[getDanweiNum].phone"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                            height="80px"
                          >
                            电话
                          </td>
                          <td colspan="2">
                            <input
                              type="text"
                              v-model="accomplish[getDanweiNum].phones"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                            />
                          </td>
                          <td
                            style="text-align: center"
                            colspan="2"
                            width="10%"
                          >
                            电子邮箱
                          </td>
                          <td colspan="3">
                            <input
                              type="text"
                              v-model="accomplish[getDanweiNum].mail"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="8" height="200px">
                            <div style="margin-bottom: 10px">
                              科技创新、推广应用情况：（限800字）
                            </div>
                            <div>
                              <el-input
                                v-if="iss"
                                type="textarea"
                                cols="70"
                                v-model="accomplish[getDanweiNum].case"
                                style="font-size: 30px; width: 100%"
                                rows="8"
                              ></el-input>
                              <div
                                style="
                                  text-align: left;
                                  box-sizing: border-box;
                                  padding: 15px 10px;
                                "
                                v-else
                                v-html="accomplish[getDanweiNum].case"
                              ></div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="8" height="220px">
                            <div class="biaoge">
                              <div>
                                <div>
                                  本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                                </div>
                              </div>
                              <div>
                                <div></div>
                                <div>单位（盖章）</div>
                              </div>
                              <div class="faren"></div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align: center">日期</td>
                          <td width="70%" colspan="5">
                            <input
                              type="text"
                              style="width: 100%; text-align: center"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="feng_btn">
                    <el-button
                      @click="danweis(2 + getDanweiNum, 4 + getDanweiNum)"
                      type="primary"
                      >保存</el-button
                    >
                    <el-button
                      @click="danweisee(2 + getDanweiNum, 4 + getDanweiNum)"
                      type="primary"
                      >下载</el-button
                    >
                    <el-button @click="danweiShow = true" type="primary"
                      >返回</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isShow == 4">
              <div v-if="renShow">
                <div class="w-cs">
                  <div v-for="item in Number(wanchengren)" :key="item">
                    <div>完成人{{ item }}</div>
                    <el-button type="primary" @click="getRen(item)"
                      >填写</el-button
                    >
                    <el-button type="primary" @click="getRen(item)"
                      >查看</el-button
                    >
                  </div>
                </div>
                <div class="feng_btn">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <div>填写每个表格保存后，请点击提交上传至系统保存</div>
                    <el-button @click="clickXia1s1" type="primary"
                      >提交</el-button
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="tabls" v-if="ise == 1">
                  <div>完成人{{ getRenNum + 1 }}</div>
                  <div
                    id="printTest"
                    ref="imageWrapper"
                    style="padding-top: 100px"
                  >
                    <div>
                      <h3 class="zzzzzz">完成人情况表</h3>
                    </div>
                    <table border="1" cellpading="0" cellspace="0">
                      <tbody>
                        <tr>
                          <td
                            colspan="1"
                            height="80px"
                            style="text-align: center"
                          >
                            姓名
                          </td>
                          <td>
                            <input
                              type="text"
                              v-model="accomplish_people[getRenNum].name"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                                text-align: center;
                              "
                            />
                          </td>
                          <td style="text-align: center">性别</td>
                          <td>
                            <input
                              type="text"
                              v-model="accomplish_people[getRenNum].sex"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                                text-align: center;
                              "
                            />
                          </td>
                          <td style="text-align: center">排名</td>
                          <td colspan="3">
                            <input
                              type="text"
                              v-model="accomplish_people[getRenNum].ranking"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                                text-align: center;
                              "
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            height="80px"
                            colspan="1"
                            style="text-align: center"
                          >
                            工作单位
                          </td>
                          <td colspan="7">
                            <textarea
                              v-if="iss"
                              type="text"
                              style="
                                border: 1px solid #000;
                                width: 100%;
                                height: 90%;
                                box-sizing: border-box;
                                padding: 0 10px;
                                font-size: 28px;
                              "
                              v-model="accomplish_people[getRenNum].unit"
                            />
                            <div
                              style="
                                text-align: left;
                                box-sizing: border-box;
                                padding: 0 10px;
                              "
                              v-else
                              v-html="accomplish_people[getRenNum].unit"
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="1"
                            width="16%;"
                            height="80px"
                            style="text-align: center"
                          >
                            职务
                          </td>
                          <td height="80px">
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                                text-align: center;
                              "
                              v-model="accomplish_people[getRenNum].job"
                            />
                          </td>
                          <td width="10%" style="text-align: center">职称</td>
                          <td>
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                                text-align: center;
                              "
                              v-model="accomplish_people[getRenNum].technical"
                            />
                          </td>
                          <td
                            width="10%"
                            colspan="2"
                            style="text-align: center"
                          >
                            身份证
                          </td>
                          <td colspan="3">
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                              v-model="accomplish_people[getRenNum].identity"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="10%"
                            colspan="1"
                            style="text-align: center"
                          >
                            通信地址
                          </td>
                          <td colspan="3">
                            <textarea
                              v-if="iss"
                              type="text"
                              style="
                                border: 1px solid #000;
                                width: 100%;
                                height: 90%;
                                box-sizing: border-box;
                                padding: 0 10px;
                                font-size: 28px;
                              "
                              v-model="accomplish_people[getRenNum].site"
                            />
                            <div
                              style="
                                text-align: left;
                                box-sizing: border-box;
                                padding: 0 10px;
                              "
                              v-else
                              v-html="accomplish_people[getRenNum].site"
                            ></div>
                          </td>
                          <td
                            width="10%"
                            colspan="2"
                            style="text-align: center"
                          >
                            邮编
                          </td>
                          <td colspan="2">
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                              v-model="accomplish_people[getRenNum].postcode"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="10% "
                            colspan="1"
                            style="text-align: center"
                          >
                            电 话
                          </td>
                          <td height="80px" colspan="3">
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                              v-model="accomplish_people[getRenNum].phone"
                            />
                          </td>
                          <td
                            width="10%"
                            colspan="2"
                            style="text-align: center"
                          >
                            手机
                          </td>
                          <td colspan="2">
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                              v-model="accomplish_people[getRenNum].phones"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="10%"
                            colspan="1"
                            style="text-align: center"
                          >
                            电子邮箱
                          </td>
                          <td height="80px" colspan="3">
                            <input
                              type="text"
                              style="
                                width: 100%;
                                padding: 0 10px;
                                box-sizing: border-box;
                              "
                              v-model="accomplish_people[getRenNum].mail"
                            />
                          </td>
                          <td
                            width="10%"
                            colspan="2"
                            style="text-align: center"
                          >
                            签字
                          </td>
                          <td colspan="2">
                            <input type="text" style="width: 200px" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="feng_btn">
                    <el-button
                      @click="danweise(2 + getRenNum, 9 + getRenNum)"
                      type="primary"
                      >保存</el-button
                    >
                    <el-button
                      @click="danweiseee(2 + getRenNum, 9 + getRenNum)"
                      type="primary"
                      >下载</el-button
                    >
                    <el-button @click="renShow = true" type="primary"
                      >返回</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isShow == 5" class="ccc">
              <div class="tabls">
                <div
                  id="printTest"
                  ref="imageWrapper"
                  style="padding-top: 100px"
                >
                  <div>
                    <h3 class="zzzzzz">推荐意见</h3>
                  </div>
                  <table border="1" cellpading="0" cellspace="0">
                    <tbody>
                      <tr>
                        <td colspan="2" width="20%">
                          推荐单位
                          <div>/ 推荐专家</div>
                        </td>
                        <td colspan="4">
                          <textarea
                            v-if="iss"
                            type="text"
                            style="
                              border: 1px solid #000;
                              width: 100%;
                              height: 90%;
                              box-sizing: border-box;
                              padding: 0 10px;
                              font-size: 28px;
                            "
                            v-model="xinxi2.recommend"
                          />
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 0 10px;
                            "
                            v-else
                            v-html="xinxi2.recommend"
                          ></div>
                        </td>
                        <td colspan="2">
                          信用代码
                          <div>/ 身份证号</div>
                        </td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi2.recommend_credit_code"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">通信地址</td>
                        <td colspan="4">
                          <textarea
                            v-if="iss"
                            type="text"
                            style="
                              border: 1px solid #000;
                              width: 100%;
                              height: 90%;
                              box-sizing: border-box;
                              padding: 0 10px;
                              font-size: 28px;
                            "
                            v-model="xinxi2.mailing_address"
                          />
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 0 10px;
                            "
                            v-else
                            v-html="xinxi2.mailing_address"
                          ></div>
                        </td>
                        <td colspan="2">邮编</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi2.recommend_code"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          联系人
                          <div>/推荐专家</div>
                        </td>
                        <td colspan="4">
                          <!-- <input
                            type="text"
                            v-model="xinxi2.recommend_name"
                            style="width: 200px"
                          /> -->
                          <textarea
                            v-if="iss"
                            type="text"
                            style="
                              border: 1px solid #000;
                              width: 100%;
                              height: 90%;
                              box-sizing: border-box;
                              padding: 0 10px;
                              font-size: 28px;
                            "
                            v-model="xinxi2.recommend_name"
                          />
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 0 10px;
                            "
                            v-else
                            v-html="xinxi2.recommend_name"
                          ></div>
                        </td>
                        <td colspan="2">联系电话</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi2.recommend_tel"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">电子邮箱</td>
                        <td colspan="4">
                          <input
                            type="text"
                            v-model="xinxi2.recommend_email"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                        <td colspan="2">手机</td>
                        <td colspan="3">
                          <input
                            type="text"
                            v-model="xinxi2.recommend_mobile"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </td>
                      </tr>

                      <tr>
                        <!-- <td colspan="1" height="320px">
                          推荐专家介绍
                          <div>(单位、职务职称、研究领域等)</div>
                        </td>
                        <td colspan="8" height="320px">
                          <div>
                            <el-input
                              v-if="iss"
                              type="textarea"
                              v-model="xinxi2.recommend_zhuanjia"
                              cols="40"
                              rows="8"
                              style="font-size: 30px"
                            ></el-input>
                            <div
                              style="
                                text-align: left;
                                box-sizing: border-box;
                                padding: 15px 10px;
                              "
                              v-else
                              v-html="xinxi2.recommend_zhuanjia"
                            ></div>
                            <div
                              style="
                                height: 60px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                              v-if="iss"
                            >
                              提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式
                            </div>
                          </div>
                        </td> -->
                        <td colspan="9" height="320px">
                          <div
                            style="width: 95%; text-align: left; margin: 15px"
                          >
                            推荐单位介绍/专家介绍(单位、职务职称、研究领域等):
                          </div>
                          <div>
                            <el-input
                              v-if="iss"
                              type="textarea"
                              v-model="xinxi2.recommend_zhuanjia"
                              cols="40"
                              rows="8"
                              style="font-size: 30px"
                              placeholder="提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式"
                            ></el-input>

                            <div
                              style="
                                text-align: left;
                                box-sizing: border-box;
                                padding: 0 10px;
                              "
                              v-else
                              v-html="xinxi2.recommend_zhuanjia"
                            ></div>
                            <div
                              style="
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: left;
                              "
                              v-if="iss"
                            >
                              提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式
                            </div>
                            <div
                              style="
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: left;
                              "
                              v-if="iss"
                            >
                              (推荐单位与推荐专家二选一)
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <!-- <td colspan="1" height="320px">推荐意见</td> -->
                        <td colspan="9" height="320px">
                          <div
                            style="width: 95%; text-align: left; margin: 15px"
                          >
                            推荐意见:
                          </div>
                          <el-input
                            v-if="iss"
                            type="textarea"
                            v-model="xinxi2.recommend_opinion"
                            cols="40"
                            style="font-size: 24px"
                            rows="6"
                          ></el-input>
                          <div
                            style="
                              text-align: left;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                            v-else
                            v-html="xinxi2.recommend_opinion"
                          ></div>
                          <div
                            style="margin: 30px 20px 20px 0; text-align: right"
                          >
                            推荐专家签字/推荐单位（盖章）
                          </div>
                          <div style="text-align: right">
                            <div
                              style="
                                display: flex;
                                margin: 0 120px 80px 0;
                                flex-direction: row-reverse;
                              "
                            >
                              <span>日</span
                              ><span style="display: block; margin: 0 30px"
                                >月</span
                              ><span>年</span>
                            </div>
                          </div>
                          <!-- <div class="biaoge">
                          <div>
                            <div></div>
                            <div>推荐专家签字/推荐单位（盖章）</div>
                          </div>
                          <div class="faren"></div>
                        </div> -->
                        </td>
                      </tr>
                      <!-- <tr>
                        <td colspan="1" height="320px">推荐意见</td>
                        <td colspan="8" height="320px">
                          <el-input
                            v-if="iss"
                            type="textarea"
                            v-model="xinxi2.recommend_opinion"
                            cols="40"
                            rows="8"
                            style="font-size: 30px"
                          ></el-input>
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 15px 10px;
                            "
                            v-else
                            v-html="xinxi2.recommend_opinion"
                          ></div>
                        </td>
                      </tr> -->
                      <!-- <tr>
                        <td colspan="9" height="220px">
                          <div class="biaoge">
                            <div>
                              <div class="biaoges">
                                本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                              </div>
                            </div>
                            <div>
                              <div></div>
                              <div>推荐专家签字/推荐单位（盖章）</div>
                            </div>
                            <div class="faren"></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">日期</td>
                        <td colspan="8">
                          <input
                            type="text"
                            style="
                              width: 100%;
                              padding: 0 10px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                            v-model="xinxi2.recommend_date"
                          />
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="feng_btn">
                <el-button @click="xiayibu1" type="primary">保存</el-button>
                <el-button @click="xiayibu1ss" type="primary">下载</el-button>
              </div>
            </div>
          </div>
          <div v-else-if="num == 2">
            <div class="shangchuan" v-if="isShows == 1">
              <div>
                <el-button @click="xiazai" style="margin-bottom: 20px"
                  >下载模板</el-button
                >
                <div style="margin-bottom: 20px">
                  请先下载模板，并依照模板填写内容，填写后，同时生成pdf文件上传至平台。
                </div>
                <el-upload
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                  class="upload-demo"
                  ref="upload"
                  action
                  :http-request="uploadFile"
                  :on-change="handleChange"
                  :show-file-list="false"
                  :file-list="fileList"
                  :on-preview="handlePictureCardPreview"
                >
                  <el-button type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传PDF文档</div>
                </el-upload>
                <div
                  v-if="fileList.length != 0"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <el-button type="primary" @click="ylan">预览</el-button>
                  <el-button @click="xiaoxiabu" type="primary">保存</el-button>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="num == 3">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0;
              "
            >
              <el-alert
                title="提示：添加完文件后，请点击上传按钮。PDF文件名中请勿带有小数点和特殊符号，文件需控制在30M以下"
                type="success"
                style="width: 70%; font-size: 22px"
              ></el-alert>
            </div>
            <div class="shangchuan1">
              <div class="box">
                <div>
                  申报单位基本信息表、完成单位情况表、完成人情况表盖章签字扫描件（必上传带水印版）
                </div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      style="width: 113px; height: 45px"
                      @click="kan(obj.accessory1)"
                      >{{
                        obj.accessory1 == null || obj.accessory1 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile1"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory1', 1)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>推荐意见表盖章扫描件（必上传带水印版）</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory2)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory2 == null || obj.accessory2 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile2"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory2', 2)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>
                  所有完成单位的营业执照、法人证书、社团登记证扫描件（必上传）
                </div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory3)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory3 == null || obj.accessory3 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile3"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory3', 3)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>申报项目知识产权相关证明扫描件</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory4)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory4 == null || obj.accessory4 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile4"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory4', 4)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>申报项目获奖证书扫描件</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory5)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory5 == null || obj.accessory5 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile5"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory5', 5)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>申报项目涉及的产学研合作协议、研发合作协议扫描件</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory6)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory6 == null || obj.accessory6 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile6"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory6', 6)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>
                  申报项目列入政府资助项目、各项计划相关文件（国家级、省级、市级）扫描件
                </div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory7)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory7 == null || obj.accessory7 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile7"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory7', 7)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>申报项目应用证明盖章扫描件</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory8)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory8 == null || obj.accessory8 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile8"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory8', 8)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>申报项目客观评价、鉴定等相关文件扫描件</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory9)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory9 == null || obj.accessory9 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile9"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory9', 9)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="box">
                <div>其他相关证明</div>
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="kan(obj.accessory10)"
                      style="width: 113px; height: 45px"
                      >{{
                        obj.accessory10 == null || obj.accessory10 == ""
                          ? "空"
                          : "预览"
                      }}</el-button
                    >
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :file-list="normal"
                      action
                      :http-request="uploadFile10"
                      :show-file-list="false"
                    >
                      <el-button
                        type="primary"
                        style="width: 113px; height: 45px"
                        >添加文件</el-button
                      >
                    </el-upload>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      @click="dels('accessory10', 10)"
                      style="width: 113px; height: 45px"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="feng_btn">
              <el-button @click="wancheng" type="primary">上传</el-button>
            </div>
          </div>
          <div class="zuihou" v-else-if="num == 4">
            <dir>
              <div>
                <h3>
                  本人知悉中国产学研合作创新与促进奖申报条件，了解有关要求
                </h3>
              </div>
              <div>1.遵守国家法律法规、社会道德风尚和科研诚信要求。</div>
              <div>
                2.填写的材料真实有效，不存在弄虚作假、侵占他人成果等行为，本人对其客观性和真实性负责。
              </div>
              <div>
                3.填写的材料符合国家保密法律法规和奖项保密要求，不涉及国家秘密，可用于评审。
              </div>
              <div style="color: red">
                <input
                  class="chea"
                  name="Fruit"
                  type="checkbox"
                  v-model="showss"
                  value=""
                />本人对以上内容及全部申报附件材料的客观性和真实性负责
              </div>
              <div>
                <el-button @click="wancheng1" type="primary"
                  >确认提交</el-button
                >
                <el-button @click="daochus" type="primary">打包下载</el-button>
              </div>
            </dir>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :show-close="false"
      :visible.sync="centerDialogVisible"
      width="700px"
      center
    >
      <div class="tijiaos">
        <div>提交成功</div>
        <div style="font-weight: bold; font-size: 24px">
          重要揭示: 请严格按照纸质版材料装订要求进行装订
        </div>
        <div>请于2023年9月10日前将纸质申报材料快递至促进会评奖办</div>
        <div>地址: 北京市海淀区学清路18号院8幢中国产学研合作促进会</div>
        <div>电话:(010)68987182</div>
        <div>
          <div @click="tishi1">下载装订要求</div>
          <div @click="centerDialogVisible = false">返回</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Print from "vue-print-nb";
// import Vue from "vue";
// Vue.use(Print);
import mammoth from "mammoth";
import html2canvas from "html2canvas";
import {
  upload_file,
  user_chile_details,
  innovation_success,
  innovation_select,
  award_find,
  upload_pdf,
  user_file,
  download_file,
  file_download,
  del_file,
} from "../axios/api";
import print from "print-js";
import Watermark from "../assets/static/js/watermark";
export default {
  data() {
    return {
      getRenNum: null,
      getDanweiNum: null,
      danweiShow: true,
      renShow: true,
      wanchenggongsi: "3",
      wanchengren: "5",
      showss: false,
      iss: true,
      ise: 1,
      user_split_id: "",
      titleList: [
        {
          title: "设计方案1",
        },
        {
          title: "设计方案2",
        },
        {
          title: "设计方案3",
        },
        {
          title: "设计方案4",
        },
      ],
      centerDialogVisible: false,
      pdfUrl: "",
      shuzu: [],
      obj: {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "7",
        accessory1: "",
        accessory2: "",
        accessory3: "",
        accessory4: "",
        accessory5: "",
        accessory6: "",
        accessory7: "",
        accessory8: "",
        accessory9: "",
        accessory10: "",
      },
      is: 1,
      url: "",
      id: "",
      textarea: "",
      fileList: [],
      fileLists: [],
      normal: [],
      select: [],
      user_name: "",
      num: 0,
      xinxi: {},
      xinxi1: {},
      xinxi2: {},
      shuzus: false,
      vHtml: "",
      isShows: 1,
      isShowss: 1,
      danwei: {},
      is: 1,
      user: { name: "", age: "" },
      stu: [
        { name: "张三", age: 17 },
        { name: "里斯", age: 18 },
        { name: "王五", age: 19 },
      ],

      isShow: true,
      accomplish: [
        {
          id: 1,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 2,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 3,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 4,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 5,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
      ],
      fengmian: {},
      accomplish_people: [
        {
          id: 1,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 2,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 3,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 4,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 5,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 6,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 7,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 8,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 9,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 10,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
      ],
    };
  },

  created() {
    this.user_chile_details();
    this.innovation_select();
  },
  components: {},

  methods: {
    tishi1() {
      window.open(`https://note.ciur.org.cn/tishi1.pdf`);
    },
    getRen(e) {
      this.getRenNum = e - 1;
      this.renShow = false;
    },
    getDanwei(e) {
      this.getDanweiNum = e - 1;
      this.danweiShow = false;
    },
    dels(val, type) {
      let data = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: type,
      };
      del_file(data).then((res) => {
        if (res.data.code == 200) {
          this.obj[val] = null;
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      });
    },
    danweis(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: i,
              token: sessionStorage.getItem("Token"),
              type_name: `第${e - 1}完成单位`,
            };
            user_file(params).then((res) => {
              if (res.data.msg == "成功") {
                // this.is = e == 6 ? 5 : e;
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    danweisee(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=完成单位`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    danweise(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: i,
              token: sessionStorage.getItem("Token"),
              type_name: `完成人${e - 1}`,
            };
            user_file(params).then((res) => {
              if (res.data.msg == "成功") {
                // this.ise = e == 11 ? 10 : e;
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    danweiseee(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=完成人`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    checkedOne(id) {
      console.log(id);
      let idIndex = this.shuzu.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.shuzu.splice(idIndex, 1);
      } else {
        // 选中该checkbox
        console.log(this.shuzu);
        if (this.shuzu == "") {
          this.shuzu = [];
        }
        this.shuzu.push(id);
      }
      this.xinxi.domain = this.shuzu;
      console.log(this.xinxi.domain);
    },
    innovation_select() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      innovation_select(params).then((res) => {
        console.log(res);
        this.select = res.data.data;
      });
    },
    user_chile_details() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      user_chile_details(params).then((res) => {
        this.user_split_id = res.data.data.id;
        this.id = res.data.data.id;
        if (res.data.data.domain != null) {
          this.shuzu = res.data.data.domain;
          console.log(this.shuzu);
        } else {
          this.shuzus = true;
        }
        this.obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          type: "7",
          accessory1: res.data.data.accessory1,
          accessory2: res.data.data.accessory2,
          accessory3: res.data.data.accessory3,
          accessory4: res.data.data.accessory4,
          accessory5: res.data.data.accessory5,
          accessory6: res.data.data.accessory6,
          accessory7: res.data.data.accessory7,
          accessory8: res.data.data.accessory8,
          accessory9: res.data.data.accessory9,
          accessory10: res.data.data.accessory10,
        };
        this.fengmian = {
          token: sessionStorage.getItem("Token"),
          id: res.data.data.id,
          type: "8",
          cover_unit: res.data.data.cover_unit,
          cover_name: res.data.data.cover_name,
          cover_time: res.data.data.cover_time,
          cover_success: res.data.data.cover_success,
        };
        this.xinxi = {
          id: res.data.data.id,
          type: 1,
          project_name: res.data.data.project_name,
          domain_rests: res.data.data.domain_rests,
          declare_unit: res.data.data.declare_unit,
          accomplish_unit: res.data.data.accomplish_unit,
          accomplish_man: res.data.data.accomplish_man,
          recommend_unit: res.data.data.recommend_unit,
          source: res.data.data.source,
          fund_number: res.data.data.fund_number,
          patent_invention: res.data.data.patent_invention,
          authorization: res.data.data.authorization,
          project_start: res.data.data.project_start,
          project_end: res.data.data.project_end,
        };
        this.xinxi2 = {
          // res.data.data.;
          id: res.data.data.id,
          type: 5,
          recommend: res.data.data.recommend,
          recommend_zhuanjia: res.data.data.recommend_zhuanjia,
          recommend_credit_code: res.data.data.recommend_credit_code,
          recommend_code: res.data.data.recommend_code,
          mailing_address: res.data.data.mailing_address,
          recommend_name: res.data.data.recommend_name,
          recommend_tel: res.data.data.recommend_tel,
          recommend_email: res.data.data.recommend_email,
          recommend_mobile: res.data.data.recommend_mobile,
          recommend_opinion: res.data.data.recommend_opinion,
          recommend_date: res.data.data.recommend_date,
        };
        this.xinxi1 = {
          id: res.data.data.id,
          type: 2,
          work_unit: res.data.data.work_unit,
          credit_code: res.data.data.credit_code,
          user_name: res.data.data.user_name,
          job: res.data.data.job,
          unit_address: res.data.data.unit_address,
          postcode: res.data.data.postcode,
          unit_tel: res.data.data.unit_tel,
          unit_mobile: res.data.data.unit_mobile,
          department: res.data.data.department,
          department_mobile: res.data.data.department_mobile,
          mobile_phone: res.data.data.mobile_phone,
          tel_mobile: res.data.data.tel_mobile,
          email: res.data.data.email,
          unit_num: res.data.data.unit_num,
          unit_num_ratio: res.data.data.unit_num_ratio,
          old_year_earning: res.data.data.old_year_earning,
          old_year_profit: res.data.data.old_year_profit,
          old_year_expenditure: res.data.data.old_year_expenditure,
          old_year_ratio: res.data.data.old_year_ratio,
        };
        if (res.data.data.user_data != null) {
          this.url = res.data.data.user_data;
          this.fileList.push(this.url);
        }
        if (res.data.data.accomplish != null) {
          this.accomplish = res.data.data.accomplish;
          this.wanchenggongsi = res.data.data.accomplish.filter(
            (e) => e.company
          ).length;
          console.log(this.wanchenggongsi);
        } else {
          console.log(123);
          this.wanchenggongsi = "3";
          this.accomplish = [
            {
              id: 1,
              company: "",
              code: "",
              site: "",
              postcode: "",
              linkman: "",
              phone: "",
              phones: "",
              mail: "",
              case: "",
            },
            {
              id: 2,
              company: "",
              code: "",
              site: "",
              postcode: "",
              linkman: "",
              phone: "",
              phones: "",
              mail: "",
              case: "",
            },
            {
              id: 3,
              company: "",
              code: "",
              site: "",
              postcode: "",
              linkman: "",
              phone: "",
              phones: "",
              mail: "",
              case: "",
            },
            {
              id: 4,
              company: "",
              code: "",
              site: "",
              postcode: "",
              linkman: "",
              phone: "",
              phones: "",
              mail: "",
              case: "",
            },
            {
              id: 5,
              company: "",
              code: "",
              site: "",
              postcode: "",
              linkman: "",
              phone: "",
              phones: "",
              mail: "",
              case: "",
            },
          ];
        }
        if (res.data.data.accomplish_people != null) {
          this.accomplish_people = res.data.data.accomplish_people;
          this.wanchengren = res.data.data.accomplish_people.filter(
            (e) => e.name
          ).length;
        } else {
          console.log(456);
          this.wanchengren = "5";
          this.accomplish_people = [
            {
              id: 1,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 2,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 3,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 4,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 5,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 6,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 7,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 8,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 9,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
            {
              id: 10,
              name: "",
              sex: "",
              ranking: "",
              unit: "",
              job: "",
              technical: "",
              identity: "",
              site: "",
              phone: "",
              phones: "",
              mail: "",
              postcode: "",
            },
          ];
        }
      });
    },
    insert: function () {
      this.stu.push(this.user);
      this.user = {};
    },
    remove: function (index) {
      this.stu.splice(index, 1);
    },
    kan(e) {
      if (!e) {
        alert("请上传pdf在预览");
        return;
      }
      // this.centerDialogVisible = true;
      // this.pdfUrl = e;
      window.open(e, "_blank");
    },
    stepChange(current) {
      this.num = parseInt(current);
    },
    uploadFile(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "pdf";
      const extension2 = item.file.name.split(".")[1] === "PDF";
      if (!extension && !extension2) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        dd1a1f00cab048a4bab22df1ec2e5b86;
        return;
      }
      console.log(item.file);
      const form = new FormData();
      form.append("file", item.file);
      //  form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          // this.arr.forEach((item, index) => {
          //   if (item.category == 1) {
          //   }
          // });
          this.url = res.data.url;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFiles(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "pdf";
      // const extension2 = item.file.name.split(".")[1] === "doc";
      if (!extension) {
        alert("上传模板只能是 pdf格式!");
        return;
      }

      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      // upload_pdf(form).then((res) => {
      //   console.log(res);
      // });
      // upload_file(form).then((res) => {
      //   console.log(res);
      //   console.log(this.fileList);
      //   if (res.data.code == 200) {
      //     this.$message({
      //       message: "添加成功",
      //       type: "success",
      //     });
      //     // this.arr.forEach((item, index) => {
      //     //   if (item.category == 1) {
      //     //   }
      //     // });
      //     this.url = res.data.url;
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // this.fileList.forEach((item) => {
      //   item.src = res.data.url;
      // });
      // });
    },
    daochus() {
      let p = {
        user_split_id: this.id,
        token: sessionStorage.getItem("Token"),
      };
      file_download(p).then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement("a"); // 创建a标签
        link.setAttribute(
          "download",
          sessionStorage.getItem("user_name") + "成果奖"
        );
        link.href = url;
        link.click();
        URL.revokeObjectURL(url); // 释放
      });
    },
    uploadFile1(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      console.log(this.normal);
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      form.append("type", 2);
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory1 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile2(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      form.append("type", 2);
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          console.log(this.obj.accessory1);
          this.obj.accessory2 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile3(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory3 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile4(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory4 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile5(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory5 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile6(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory6 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile7(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory7 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile8(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory8 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile9(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory9 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile10(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory10 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    xiaxiasa() {
      this.num = 1;
      this.isShow = true;
    },
    xiaxia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 1,
              token: sessionStorage.getItem("Token"),
              type_name: "2023年创新成果奖申报页面首页",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                innovation_success(this.fengmian).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    handlePictureCardPreview(file) {
      console.log(file);
      // window.open(
      //   `https://view.officeapps.live.com/op/view.aspx?src=${file.src}`
      // );
    },
    handleChange(file, fileList) {
      console.log(file);
      this.fileList = fileList.slice(-1);
    },
    handleChanges(file, fileList) {
      console.log(file);
      this.fileLists = fileLists.slice(-1);
    },
    ylan() {
      window.open(this.url);
    },
    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "1300px",
        style: ["*"],
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
    baocun() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报"
        );

        // setTimeout(() => {
        //   console.log(this.$myfile);
        // }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 2,
              token: sessionStorage.getItem("Token"),
              type_name: "成果基本信息",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi.type = 1;
                this.xinxi.token = sessionStorage.getItem("Token");
                this.xinxi.domain = this.shuzu;
                innovation_success(this.xinxi).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });

                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    fengmianxiazai() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=封面`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXias() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=成果信息`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiazai() {
      let p = {
        id: 7,
      };
      award_find(p).then((res) => {
        console.log(res);
        window.location.href = res.data.data.file_url;
        // window.open(res.data.data.file_url)
      });
    },
    clickXia1s() {
      let pas = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "3",
        accomplish: this.accomplish,
      };
      innovation_success(pas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.user_chile_details();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    click1se() {
      this.num = 1;
      this.isShow = 4;
    },
    clickXia1see() {
      let pas = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "3",
        accomplish: this.accomplish,
      };
      innovation_success(pas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });

          this.user_chile_details();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    clickXia1s1e() {
      this.num = 1;
      this.isShow = 5;
    },
    clickXia1s1() {
      let pas = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 4,
        accomplish_people: this.accomplish_people,
      };
      innovation_success(pas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.user_chile_details();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    clickXia1() {
      this.isShow = true;
    },
    clickXiasa() {
      this.num = 1;
      this.isShow = 2;
    },
    xiyibuasa() {
      this.num = 1;
      this.isShow = 3;
    },
    clickXia1se() {
      this.num = 1;
      this.isShow = 6;
    },
    xiyibua() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 3,
              token: sessionStorage.getItem("Token"),
              type_name: "申报单位信息",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi1.type = 2;
                this.xinxi1.token = sessionStorage.getItem("Token");
                innovation_success(this.xinxi1).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });

                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiyibuas() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=单位信息`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    fanhui() {
      this.num = 1;
      console.log(this.num);
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1sa() {
      this.num = 2;
    },
    xiayibu1() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 19,
              token: sessionStorage.getItem("Token"),
              type_name: "推荐意见",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi2.token = sessionStorage.getItem("Token");
                this.xinxi2.type = 5;
                innovation_success(this.xinxi2).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu1ss() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=推荐意见`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },
    wancheng() {
      console.log(this.obj);
      this.obj.id = this.id;
      innovation_success(this.obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    wancheng1() {
      if (!this.showss) {
        alert("请勾选");
        return;
      }
      this.$confirm("确认所有资料提交给单位管理员").then((_) => {
        let obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          type: 9,
        };
        innovation_success(obj)
          .then((res) => {
            if (res.data.code == 200) {
              this.centerDialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((_) => {});
      });
    },
    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      let params = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 6,
        user_data: this.url,
      };
      console.log(params);
      // return;
      innovation_success(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.num = 3;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    shangyibu() {
      this.num = 1;
      this.isShow = 5;
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: calc(100% - 50px);
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  /* width: 120px; */
  text-align: center;
  font-size: 30px;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 30px;
}
.tabls input {
  background: none;

  outline: none;
  border: 0px;
  font-size: 30px;
  width: 90px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 30px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shenMain td {
  text-align: left;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
  margin-right: 10px;
}
.biaoge {
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px auto;
  position: relative;
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  text-indent: 2em;
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.zunshou div {
  margin-top: -50px;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 260px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.xuanze input {
  border: 1px solid #999999;
}
.xuanze > div {
  margin: 10px 5px;
}
/* .xuanze {
  width: 100%;
}
.xuanze .input-checkbox {
  width: 30px;
} */
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
.shangchuan > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clickXia1_main {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-items: center;
  background-color: pink;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.clickXia1_main1 > div {
  margin-left: 50px;
}
.clickXia1_main > div {
  margin-left: 10px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}
.box > div:nth-child(1) {
  width: 700px;
}
.box > div:nth-child(2) {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aaa {
  display: flex;
}

.tianjia {
  width: 100px !important;
  height: 50px;
  display: flex;
  justify-content: center;

  align-items: center;
  background-color: #409eff;
  cursor: pointer;
  color: #fff;
}
.tianjia1 {
  width: 100px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #409eff;
  cursor: pointer;
  color: #fff;
  margin-right: 20px;
}

.fengmian {
  width: 1200px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  /* padding-bottom: 100px; */
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  flex: 0 0 240px;
  font-size: 22px;
}
.ccc td {
  text-align: center;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 30px;
  border: 0px;
  border-bottom: 1px solid #999;
}
.feng_btn > div {
  margin: 10px 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.faren {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.faren > div {
  margin-left: 50px;
}
.yiqi {
  display: flex;
  height: calc(100vh - 170px);
  /* background-color: pink; */
}
.yiqi > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yiqi > div:nth-child(1) > div {
  width: 180px;
  color: rgba(70, 57, 57, 0.83);
  cursor: pointer;
  font-size: 20px;
  margin: 15px 0;
}
.yiqi > div:nth-child(1) > div i {
  margin-right: 5px;
}
.yiqi > div:nth-child(1) {
  width: 15%;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
}
.yiqi > div:nth-child(3) {
  box-sizing: border-box;
  padding: 20px;
}
.yiqi > div:nth-child(2) {
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
}
.yiqi > div:nth-child(1) .colors {
  color: #409eff;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.chea {
  margin: 0;
}
.zuihou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zuihou div {
  width: 800px;
  margin: 40px;
}
.tijiaos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tijiaos > div {
  font-size: 20px;
}
.tijiaos > div:nth-child(1) {
  font-size: 70px;
  font-weight: bold;
}
.tijiaos > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tijiaos > div:last-child > div {
  width: 150px;
  border-radius: 10px;
  height: 40px;
  background: #409efe;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
}
.tijiaos > div {
  margin: 10px 0;
}
.biaoges {
  box-sizing: border-box;
  padding: 0 10px;
  position: absolute;
}
/* .feng_btn > div {
  padding: 10px;
} */
.w-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;
}
.w-c > div {
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  margin: 40px 0;
}
.w-c > div > div {
  margin-bottom: 20px;
}
.w-cs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
}
.w-cs > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 15px;
}
.w-cs > div > div {
  margin-right: 100px;
}
</style>
